import React from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Overwrite } from 'utility-types'
import { makeCommuterGuideRepository } from 'api/modules/commuterGuides'
import { useCurrentAccount } from './CurrentAccount'
import { useCommuterGuide } from '@commutifi-fe/commutifi-specific/Presentation/CommuterGuide/hooks'
import { CommuterGuideRepository } from '@commutifi-fe/commutifi-specific/Domain/Repositories/CommuterGuide'
import { CommuterGuide } from '@commutifi-fe/commutifi-specific/Domain/Models/CommuterGuide'
import { CommutifiErrorData } from '@commutifi-fe/interfaces'
import { CommutifiError } from 'shared/interfaces'

interface CommuterGuidesProviderProps {
  /**
   * Enable data fetching in the provider. This allows us to use
   * the provider at top level tree without being concerned to fetch
   * too many times the data when we don't need it
   */
  enabled?: boolean
  children: React.ReactNode
}

type ICommuterGuidesContext = Overwrite<
  Partial<UseQueryResult<CommuterGuide[], CommutifiError>>,
  { isError: boolean }
> & {
  commuterGuide?: CommuterGuide
  error: AxiosResponse<CommutifiErrorData, any> | null
}

export const CommuterGuidesContext = React.createContext<ICommuterGuidesContext | null>(null)

const commuterGuideRepository = makeCommuterGuideRepository()
function CommuterGuidesProvider({ enabled, children }: CommuterGuidesProviderProps) {
  const { office, currentAccount } = useCurrentAccount()
  const enterpriseId = currentAccount.enterpriseId || currentAccount.organizationId || undefined
  const { buildingId } = office
  const request = React.useMemo(
    () => ({ queryParams: { enterpriseId, buildingId }, queryOptions: { enabled } }),
    [enterpriseId, buildingId, enabled]
  )

  const fetchCommuterGuideUseCase = useCommuterGuide(commuterGuideRepository(request))

  const { isLoading, isFetching, isError, isFetched, error, data: commuterGuide } = fetchCommuterGuideUseCase.execute()

  const value = React.useMemo(
    () => ({
      isLoading,
      isFetching,
      isFetched,
      isError,
      error,
      commuterGuide
    }),
    [isLoading, isFetching, isFetched, isError, error, commuterGuide]
  )
  if (!enabled) {
    return children
  }
  return <CommuterGuidesContext.Provider value={value}>{children}</CommuterGuidesContext.Provider>
}

const useCommuterGuides = () => {
  const context = React.useContext(CommuterGuidesContext)
  if (!context) {
    throw new Error('useCommuteProfile must be used within a CommuterGuidesProvider')
  }
  return context
}

export { CommuterGuidesProvider, useCommuterGuides }

export function makeCommuterGuideProviderRepository<
  TQueryParams extends Record<string, any>
>(): CommuterGuideRepository<TQueryParams> {
  return {
    ...makeCommuterGuideRepository(),
    useCommuterGuide: () => {
      const { isLoading, error, isFetched, commuterGuide } = useCommuterGuides()
      return {
        isLoading: Boolean(isLoading),
        error,
        isError: Boolean(error),
        isFetching: Boolean(isLoading && !commuterGuide),
        isFetched: Boolean(isFetched),
        data: commuterGuide
      }
    }
  }
}
