import React from 'react'
import { Form, FormItemProps, Input } from '@commutifi-fe/ui'
import { INTL_ID_VALIDATION_REQUIREMENTS, PASSWORD_VALIDATION_REGEX } from '@commutifi-fe/utils'
import { useIntl } from 'locales/index'

interface PasswordInputProps extends Omit<FormItemProps, 'children'> {
  children?: React.ReactElement
}

/**
 * IMPORTANT: this component can only be used in and ant design Form component
 */
function PasswordInput({ children, label, rules, ...otherFormItemProps }: PasswordInputProps) {
  const intl = useIntl()
  return (
    <Form.Item
      label={label || intl.formatMessage({ id: 'signup.account.password' })}
      name="password"
      validateTrigger={['onChange', 'onBlur']}
      rules={
        rules || [
          { required: true, message: intl.formatMessage({ id: 'required' }), validateTrigger: 'onChange' },
          {
            // .{8,} Asserts that the entire string is a composition of at least 8 "anything, except new line".
            // (?=.*[a-z]) use positive look ahead to see if at least one lower case letter exists
            // (?=.*[A-Z]) use positive look ahead to see if at least one upper case letter exists
            // (?=.*\d) use positive look ahead to see if at least one digit exists
            pattern: PASSWORD_VALIDATION_REGEX,
            message: intl.formatMessage({ id: INTL_ID_VALIDATION_REQUIREMENTS }),
            validateTrigger: 'onBlur'
          }
        ]
      }
      {...otherFormItemProps}
    >
      {children || <PasswordField />}
    </Form.Item>
  )
}

export function PasswordField(props: React.ComponentProps<typeof Input.Password>) {
  const intl = useIntl()
  return <Input.Password type="password" placeholder={intl.formatMessage({ id: 'placeholder.password' })} {...props} />
}

export default PasswordInput
