import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useReducer, useRef } from 'react'

const useDeepCompareMemoize = (value: React.DependencyList) => {
  const ref = useRef<React.DependencyList>()
  const signalRef = useRef<number>(0)

  if (!isEqual(value, ref.current)) {
    ref.current = value
    signalRef.current += 1
  }

  return [signalRef.current]
}

export function useSetState<T = any>(initialState: T) {
  const [state, setState] = useReducer((s: any, newState: any) => ({ ...s, ...newState }), initialState)
  return [state, setState]
}

export const useIsMountedRef = (): { readonly current: boolean } => {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return isMounted
}

export function useSafeSetState<T = any>(initialState: T) {
  const [state, setState] = useSetState(initialState)
  const isMounted = useIsMountedRef()
  const safeSetState = (...args: any) => isMounted.current && setState(...args)
  return [state, safeSetState]
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useDeepCompareEffect(effect: React.EffectCallback, dependencies: React.DependencyList) {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy
  useEffect(effect, useDeepCompareMemoize(dependencies))
}

export function useDeepCompareCallback<T extends (...args: any[]) => any>(
  callback: T,
  dependencies: React.DependencyList
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy
  return useCallback(callback, useDeepCompareMemoize(dependencies))
}

export const useUpdateEffect = (effect: React.EffectCallback, deps: React.DependencyList) => {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      return effect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy
  }, deps)
}

export const useDebounce = (
  fn = () => {
    /**/
  },
  ms = 0,
  deps: any[] = []
) => {
  useUpdateEffect(() => {
    const timeout = setTimeout(fn, ms)

    return () => {
      clearTimeout(timeout)
    }
  }, deps)
}
