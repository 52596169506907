import React from 'react'
import { policyLink, termsLink } from '@commutifi-fe/constants'
import { FormattedMessage } from 'react-intl'

interface TermsProps {
  className?: string
}

export function TermsAndPolicy({ className }: TermsProps) {
  // <p></p> instead of <></> because it gives a bottom padding just like we want
  return (
    <p className={className}>
      <FormattedMessage
        id="termsAndPrivacy"
        values={{
          termsLink: (
            <a target="_blank" rel="noreferrer" href={termsLink} aria-label="Commutifi terms of use">
              <FormattedMessage id="termsOfUse" />
            </a>
          ),
          policyLink: (
            <a target="_blank" rel="noreferrer" href={policyLink} aria-label="Commutifi privacy policy">
              <FormattedMessage id="privacyPolicy" />
            </a>
          )
        }}
      />
    </p>
  )
}

export default TermsAndPolicy
