import { colors } from 'constants/global'
import React from 'react'
import { CommutifiLogo } from '@commutifi-fe/ui'
import styles from './styles.module.scss'

export function ModalLogo() {
  return (
    <div className={styles['circle-container']}>
      <div className={styles.circle}>
        <CommutifiLogo hideText width={65} fill={colors.primary400} />
      </div>
    </div>
  )
}

export default ModalLogo
