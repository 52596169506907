import type { AddressPoint, AddressPointWithTimezone } from './Commute'
import type { Location } from './Location'
import type { GeocoderResult } from './thirdParty/google'

export interface Address {
  street: string
  streetNumber?: string
  state: string
  city: string
  country?: string
  postalCode: string
}
export const isAddress = (addr: unknown): addr is Address =>
  Boolean(
    addr &&
      typeof addr === 'object' &&
      (Object.prototype.hasOwnProperty.call(addr, 'streetNumber') ||
        Object.prototype.hasOwnProperty.call(addr, 'street'))
  )

export type OfficeGeocodeValue = AddressPointWithTimezone & {
  value?: string
  /**
   * Optional label that can be used to display a specific label in a Select component for example
   */
  label?: React.ReactNode
}

const getLocationFromGoogleGeocode = (geocode: GeocoderResult): Location => ({
  lat: geocode.geometry.location.lat(),
  lng: geocode.geometry.location.lng()
})

export const googleGeocodeToLocalGeocode = (geocode: GeocoderResult): AddressPoint => ({
  addressComponents: geocode.addressComponents,
  formattedAddress: geocode.formattedAddress,
  location: getLocationFromGoogleGeocode(geocode)
})
