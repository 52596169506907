import { NormalizedSchema } from 'normalizr'
import React, { Dispatch } from 'react'
import { fetchOrgEnterprises } from 'api/modules/enterprises'
import { StringSerializer, useQueryParam } from 'shared/hooks/urlQueryParams'
import { NormalizedData, arrayOfEnterprises, normalize } from 'store/schemas'
import sessionStorage from 'utils/sessionStorage'

/**
 * Reference https://kentcdodds.com/blog/application-state-management-with-react
 */
const IS_FETCHING = 'IS_FETCHING'
const SET_ENTERPRISES = 'SET_ENTERPRISES'
const FAILED = 'FAILED'
const ENTERPRISE_ID_FILTER = 'ENTERPRISE_ID_FILTER'
const CLEAR_FILTERS = 'CLEAR_FILTERS'

interface IsFetchingActionType {
  type: typeof IS_FETCHING
}
interface SetEnterprisesActionType {
  type: typeof SET_ENTERPRISES
  payload: NormalizedSchema<{ enterprises: NormalizedData<PartialEnterprise> }, string[]>
}
interface FailedActionType {
  type: typeof FAILED
  error: any
}
interface EnterpriseIdFilterActionType {
  type: typeof ENTERPRISE_ID_FILTER
  enterpriseId: string | undefined
}
interface ClearFiltersActionType {
  type: typeof CLEAR_FILTERS
}
type OrganizationFilterActionType =
  | IsFetchingActionType
  | SetEnterprisesActionType
  | FailedActionType
  | EnterpriseIdFilterActionType
  | ClearFiltersActionType

interface OrganizationFiltersState {
  isFetching: boolean
  loaded: boolean
  enterprisesIds: string[] | null
  enterpriseId: string | null | undefined
  enterprisesById: Record<string, PartialEnterprise> | null
}

export interface PartialEnterprise {
  id: string
  name: string
  employeesCount: number
}

const FilterId = 'enterpriseFilterId'
const OrganizationFiltersContext = React.createContext<
  [OrganizationFiltersState & { enterprises: PartialEnterprise[] }, Dispatch<OrganizationFilterActionType>] | null
>(null)
const organizationFiltersReducer = (state: OrganizationFiltersState, action: OrganizationFilterActionType) => {
  switch (action.type) {
    case IS_FETCHING: {
      return { ...state, isFetching: true }
    }
    case SET_ENTERPRISES: {
      return {
        ...state,
        enterprisesIds: action.payload.result,
        enterprisesById: action.payload.entities.enterprises,
        loaded: true
      }
    }
    case FAILED: {
      return { ...state, isFetching: false, loaded: false }
    }
    case ENTERPRISE_ID_FILTER: {
      if (action.enterpriseId) {
        sessionStorage.put(FilterId, action.enterpriseId)
      } else {
        sessionStorage.remove(FilterId)
      }
      return { ...state, enterpriseId: action.enterpriseId }
    }
    case CLEAR_FILTERS: {
      sessionStorage.remove(FilterId)
      return { ...state, enterpriseId: null }
    }
    default: {
      throw new Error('Unsupported action type')
    }
  }
}

const setEnterpriseFilter = (enterpriseId: string | undefined): EnterpriseIdFilterActionType => ({
  type: ENTERPRISE_ID_FILTER,
  enterpriseId
})

export interface OrganizationFiltersProviderProps {
  // This boolean is needed until we remove the concept
  // of org vs enterprises and we simply use enterprise children.
  // Then we don't need to disable it since any enterprise with children
  // will have possible filters to apply and see only one child data
  enabled?: boolean
  organizationId: string
  children: React.ReactNode
}

const getEnterprises = ({ enterprisesIds, enterprisesById }: OrganizationFiltersState) =>
  (enterprisesIds || []).map((id) => enterprisesById?.[id]).filter((e) => e) as PartialEnterprise[]

function OrganizationFiltersProvider({ enabled, organizationId, ...rest }: OrganizationFiltersProviderProps) {
  const [state, dispatch] = React.useReducer(organizationFiltersReducer, {
    enterprisesIds: null,
    enterprisesById: null,
    enterpriseId: sessionStorage.get(FilterId),
    isFetching: false,
    loaded: false
  })

  React.useEffect(() => {
    if (enabled) {
      const fetch = async (orgId: string) => {
        try {
          dispatch({ type: IS_FETCHING })
          const { data: enterprises } = await fetchOrgEnterprises(orgId, { fields: ['id', 'name'] })
          dispatch({ type: SET_ENTERPRISES, payload: normalize(enterprises, arrayOfEnterprises) })
        } catch (error) {
          dispatch({ type: FAILED, error })
        }
      }
      fetch(organizationId)
    }
  }, [enabled, organizationId])

  const [enterpriseId] = useQueryParam('enterpriseId', StringSerializer)
  const persistedFilterId = sessionStorage.get(FilterId)
  React.useEffect(() => {
    dispatch({
      type: ENTERPRISE_ID_FILTER,
      enterpriseId: enterpriseId ?? persistedFilterId
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy
  }, [enterpriseId])
  const value = React.useMemo<
    [OrganizationFiltersState & { enterprises: PartialEnterprise[] }, Dispatch<OrganizationFilterActionType>]
  >(() => [{ ...state, enterprises: getEnterprises(state) }, dispatch], [state])
  return <OrganizationFiltersContext.Provider value={value} {...rest} />
}

const useOrganizationFilters = () => {
  const context = React.useContext(OrganizationFiltersContext)
  if (!context) {
    throw new Error('useOrganizationFilters must be used within a OrganizationFiltersProvider')
  }
  const [state, dispatch] = context

  return {
    state,
    currentEnterprise: state.enterprisesById?.[state.enterpriseId || ''],
    dispatch,
    ActionTypes: {
      setEnterpriseFilter
    }
  }
}

export { OrganizationFiltersProvider, useOrganizationFilters }
