import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { accountSelectors } from 'store/modules/accounts'
import { userMetricsActions, userMetricsSelectors } from 'store/modules/metrics/user'

interface CommutifiMetrics {
  carbon: number
  cost: number
  time: number
}

interface OrganizationMetricsProviderValue {
  regionScore: number
  regionMetrics: CommutifiMetrics
  score: number
  metrics: CommutifiMetrics
  isLoading?: boolean
  fetchMetrics: (orgId: string, params: { enterpriseId?: string }) => any
}

interface OrganizationMetricsProviderProps extends OrganizationMetricsProviderValue {
  children: React.ReactNode
  organizationId?: string
  enterpriseId?: string
}

export const OrganizationMetricsContext = React.createContext<OrganizationMetricsProviderValue | null>(null)

export function OrganizationMetricsProvider({
  children,
  fetchMetrics,
  metrics,
  regionMetrics,
  score,
  regionScore,
  isLoading,
  organizationId, // Prop from parent component
  // Enterprise id is only specified when an organization is filtering the
  // Admin dashboard for a specific enterprise
  enterpriseId // Prop from parent component
}: OrganizationMetricsProviderProps) {
  const fetchOrgMetricsCallback = React.useCallback(() => {
    if (organizationId) {
      fetchMetrics(organizationId, {
        ...(enterpriseId && { enterpriseId })
      })
    }
  }, [organizationId, fetchMetrics, enterpriseId])

  useEffect(() => {
    fetchOrgMetricsCallback()
  }, [fetchOrgMetricsCallback])

  const value = React.useMemo(
    () => ({
      isLoading,
      metrics,
      regionMetrics,
      score,
      regionScore,
      fetchMetrics: fetchOrgMetricsCallback
    }),
    [fetchOrgMetricsCallback, isLoading, metrics, regionMetrics, regionScore, score]
  )

  return <OrganizationMetricsContext.Provider value={value}>{children}</OrganizationMetricsContext.Provider>
}

const mapStateToProps = (state: any) => ({
  currentUserId: accountSelectors.getCurrentId(state),
  metrics: userMetricsSelectors.makeGetConvertedScoreFactors('organization')(state),
  regionMetrics: userMetricsSelectors.makeGetConvertedScoreFactors('region')(state),
  score: userMetricsSelectors.makeGetScore('organization')(state),
  regionScore: userMetricsSelectors.makeGetScore('region')(state),
  isLoading: userMetricsSelectors.getIsFetching(state) || !userMetricsSelectors.getIsLoaded(state)
})

const mapDispatchToProps = {
  fetchMetrics: userMetricsActions.fetchMetricsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OrganizationMetricsProvider))

export const OrganizationMetricsConsumer = OrganizationMetricsContext.Consumer
