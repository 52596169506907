import { useAccountKCI } from 'api/modules/accounts'
import React from 'react'

interface CommuterMetricsProviderProps {
  accountId: string
  /**
   * Enable data fetching in the provider. This allows us to use
   * the provider at top level tree without being concerned to fetch
   * too many times the data when we don't need it
   */
  enabled?: boolean
  children: React.ReactNode
}

type CommuterMetricsContextProps = ReturnType<typeof useAccountKCI>
export const CommuterMetricsContext = React.createContext<CommuterMetricsContextProps | null>(null)

function CommuterMetricsProvider({ accountId, enabled, children }: CommuterMetricsProviderProps) {
  const state = useAccountKCI(accountId, {}, { enabled: Boolean(accountId && enabled) })

  const value = React.useMemo(() => state, [state])
  if (!enabled) {
    return children
  }
  return <CommuterMetricsContext.Provider value={value}>{children}</CommuterMetricsContext.Provider>
}

const useCommuterMetrics = () => {
  const context = React.useContext(CommuterMetricsContext)
  if (!context) {
    throw new Error('useCommuterMetricsContext must be used within a CommuterMetricsProvider')
  }
  return context
}

export { CommuterMetricsProvider, useCommuterMetrics }
