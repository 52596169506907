/**
 * Return language embedded into locale (ex. en-us has en language)
 * @param locale - code representing browser language
 */
export const getLanguage = (locale: string): 'fr' | 'en' => {
  const dashIndex = locale.indexOf('-')
  if (dashIndex >= 0) {
    const localeCode = locale.substring(0, dashIndex)
    if (localeCode !== 'fr' && localeCode !== 'en') {
      return 'en'
    }
    return localeCode
  }

  return locale as 'fr' | 'en'
}
