import logger from 'utils/logRocket'

// Thanks to this package for the inspiration: https://github.com/pbeshai/serialize-query-params/

/**
 * Encodes a the input as a string.
 * Encodes a string while safely handling null and undefined values.
 * @param num -- the number to encode
 * @returns the encoded number
 */
const encode = (num: number | string | null): string | undefined => {
  if (!num) {
    return undefined
  }

  return num.toString()
}

/**
 * Decodes a number from a string. If the number is invalid,
 * it returns undefined.
 *
 * If an array is provided, only the first entry is used.
 *
 * @param input - the encoded number string
 * @returns the number value
 */
const decodeNumber = (input: string): number | undefined => {
  const numStr = Array.isArray(input) ? input[0] : input

  if (numStr === null || numStr === '') {
    return undefined
  }

  const result = Number(numStr)

  if (Number.isNaN(result)) {
    return undefined
  }

  return result
}

/**
 * Decodes a string while safely handling null and undefined values.
 *
 * If an array is provided, only the first entry is used.
 *
 * @param input - the encoded string
 * @returns the string value
 */
const decodeString = (input: string): string | undefined => {
  const str = Array.isArray(input) ? input[0] : input
  return encode(str)
}

/**
 * Encodes an array as a JSON string.
 *
 * @param array - The array to be encoded
 * @returns The array of strings to be put in the URL
 * as repeated query parameters
 */
const encodeArray = (array: any[] | undefined): string[] | undefined => {
  if (!array) {
    return undefined
  }

  return array
}

/**
 * Decodes an array or singular value and returns it as an array
 * or undefined if falsy. Filters out undefined values.
 *
 * @param input - The input value
 * @returns The javascript representation
 */
function decodeArray<T extends string = any>(input: T | T[]): T[] | undefined {
  if (!input) {
    return undefined
  }

  if (!Array.isArray(input)) {
    return [input]
  }

  return input.map((item) => (item === '' ? undefined : item)).filter((item) => item !== undefined) as T[]
}

/**
 * Encodes a numeric array as a JSON string.
 *
 * @param array - The array to be encoded
 * @returns The array of strings to be put in the URL
 * as repeated query parameters
 */
const encodeNumericArray = (array: any[] | undefined) => {
  if (!array) {
    return undefined
  }

  return array.map((d) => `${d}`)
}

/**
 * Decodes an array or singular value and returns it as an array
 * or undefined if falsy. Filters out undefined and NaN values.
 *
 * @param input - The input value
 * @returns The javascript representation
 */
const decodeNumericArray = (input: string | string[]): number[] | undefined => {
  const arr = decodeArray(input)

  if (!arr) {
    return undefined
  }

  return arr.map((item) => Number(item)).filter((item) => !Number.isNaN(item))
}

export type SerializerTypes =
  | typeof StringSerializer
  | typeof NumberSerializer
  | typeof ArraySerializer
  | typeof NumericArraySerializer

export const encodeQueryParams = (
  queryParams: Record<string, any>,
  paramsSerializerMap: Record<string, SerializerTypes | undefined>
) =>
  Object.keys(queryParams).reduce((encodedQueryParams, paramName) => {
    if (!paramsSerializerMap[paramName]) {
      if (process.env.NODE_ENV === 'development') {
        logger.warn(
          `Encoding parameter ${paramName} as string since it was not configured when declaring useQueryParams hook.`
        )
      }
      return { ...encodedQueryParams, [paramName]: encode(queryParams[paramName]) }
    }
    return { ...encodedQueryParams, [paramName]: paramsSerializerMap[paramName]?.encode(queryParams[paramName]) }
  }, {})

/**
 * String values
 */
export const StringSerializer = {
  encode,
  decode: decodeString
}

/**
 * Numbers (integers or floats)
 */
export const NumberSerializer = {
  encode,
  decode: decodeNumber
}

/**
 * For flat arrays of strings, filters out undefined values during decode
 */
export const ArraySerializer = {
  encode: encodeArray,
  decode: decodeArray
}

/**
 * For flat arrays of strings, filters out undefined values during decode
 */
export const NumericArraySerializer = {
  encode: encodeNumericArray,
  decode: decodeNumericArray
}

// When needed we could implement json params, date, boolean and other needed serializer
// using our inspiration link state on top of this file
