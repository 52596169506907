import { compact, difference, memoize, uniq } from 'lodash'
import React from 'react'
import { useMutation, UseQueryResult } from '@tanstack/react-query'
import { Overwrite } from 'utility-types'
import { queryClient } from 'api/reactQuery'
import {
  deleteCommuteProfile,
  CommuteProfile,
  CommuteProfileProcessed,
  PatchRequestParams,
  PostCommuteProfileBody,
  PostCommuteProfileQueryParams,
  PutRequestParams,
  patchCommuteProfile,
  postCommuteProfile,
  putCommuteProfile,
  useAccountCommuteProfiles
} from 'api/modules/commuteProfiles'
import { useIntl } from 'locales/index'
import { useNotifications } from '@commutifi-fe/commutifi-specific/hooks'
import { DaysValueEnum, RouteLeg } from 'shared/interfaces/Commute'
import { CommuterRouting, SettingsPages } from 'src/routing'
import { sortByKey } from 'utils/helpers'
import { getMapDrawingSections, MapDrawingSection } from 'utils/map'
import { getDayLocale } from 'utils/moment'
import { useCurrentAccount } from './CurrentAccount'

export interface CommuteProfilesProviderProps {
  /**
   * Enable data fetching in the provider. This allows us to use
   * the provider at top level tree without being concerned to fetch
   * too many times the data when we don't need it
   */
  enabled?: boolean
  children: React.ReactNode
}

interface IModesByDay {
  [DaysValueEnum.Monday]: string[]
  [DaysValueEnum.Tuesday]: string[]
  [DaysValueEnum.Wednesday]: string[]
  [DaysValueEnum.Thursday]: string[]
  [DaysValueEnum.Friday]: string[]
  [DaysValueEnum.Saturday]: string[]
  [DaysValueEnum.Sunday]: string[]
}

export type PatchCPFunction = (data: PatchRequestParams) => void
type DeleteFunction = (commuteProfileId: string) => Promise<void>

export interface PostCommuteProfileOptions {
  queryParams?: PostCommuteProfileQueryParams
  skipNotifications?: boolean
}
type PostFunction = (
  data: PostCommuteProfileBody,
  opts?: PostCommuteProfileOptions
) => Promise<CommuteProfile | undefined>
type PutFunction = (data: PutRequestParams) => Promise<CommuteProfile | undefined>

export interface ICommuteWithDrawing extends CommuteProfileProcessed {
  drawing: MapDrawingSection[]
}
type CommuteProfilesContextValue = Overwrite<
  Partial<UseQueryResult<CommuteProfileProcessed[]>>,
  { isError: boolean }
> & {
  goingModesByCommuteDays: IModesByDay
  returnModesByCommuteDays: IModesByDay
  activeCommuteProfiles: ICommuteWithDrawing[]
  inactiveCommuteProfiles: ICommuteWithDrawing[]
  processingDay: DaysValueEnum | null
  isProcessing: boolean
  isProcessingError: boolean
  patch: PatchCPFunction
  create: PostFunction
  deleteCommute: DeleteFunction
  put: PutFunction
  getCommuteDrawingSections: (commuteProfile: CommuteProfileProcessed | undefined) => MapDrawingSection[]
  getCommuteProfile: (cpId: string) => CommuteProfileProcessed | undefined
}

export const CommuteProfilesContext = React.createContext<CommuteProfilesContextValue | null>(null)

const initialModesByDay: IModesByDay = {
  [DaysValueEnum.Monday]: [],
  [DaysValueEnum.Tuesday]: [],
  [DaysValueEnum.Wednesday]: [],
  [DaysValueEnum.Thursday]: [],
  [DaysValueEnum.Friday]: [],
  [DaysValueEnum.Saturday]: [],
  [DaysValueEnum.Sunday]: []
}

const extractCommuteActivities = (commute: CommuteProfileProcessed): CommuteProfileProcessed => ({
  ...commute,
  activities: uniq(compact(sortByKey(commute.goingLegs, 'order').map((leg: RouteLeg) => leg.activity)))
})

// In Use CP have a day in the days array (used at least one day of the week)
const makeGetInUseCommuteProfiles = (inUse = true) =>
  memoize((commuteProfiles: CommuteProfileProcessed[] = []) =>
    commuteProfiles
      .filter((cp) => (inUse ? Boolean(cp.days?.length) : !cp.days?.length))
      .map((cp) => ({ ...cp, drawing: getCommuteDrawingSections(cp) }))
  )

const getGoingCommuteProfiles = memoize((commuteProfiles: CommuteProfileProcessed[] = []) =>
  commuteProfiles.filter((cp) => !cp.isReturn && Boolean(cp.days?.length))
)
const getReturnCommuteProfiles = memoize((commuteProfiles: CommuteProfileProcessed[] = []) =>
  commuteProfiles.filter((cp) => cp.isReturn && Boolean(cp.days?.length))
)

const getCommuteDrawingSections = memoize((commuteProfile: CommuteProfileProcessed | undefined) => {
  if (!commuteProfile) {
    return []
  }

  const legs = commuteProfile.goingLegs || commuteProfile.returnLegs || []
  return getMapDrawingSections(sortByKey(legs, 'order'))
})

/**
 * Get one commute profile. We do a find because there should be only few commute profiles
 */
const getCommuteProfile = memoize(
  (commuteProfiles: CommuteProfileProcessed[] = []) =>
    (commuteProfileId: string) =>
      commuteProfiles.find((cp) => cp.id === commuteProfileId)
)

const getModesByCommuteDay = memoize(
  (commuteProfiles: CommuteProfileProcessed[] = [], type: 'going' | 'return' = 'going') => {
    if (!commuteProfiles.length) {
      return initialModesByDay
    }

    const filterCommuteProfilesFn = {
      going: getGoingCommuteProfiles,
      return: getReturnCommuteProfiles
    }

    const goingCommuteProfiles = filterCommuteProfilesFn[type](commuteProfiles)
    return goingCommuteProfiles.reduce<IModesByDay>(
      (modesByDay: IModesByDay, commuteProfile: CommuteProfileProcessed) => {
        const updatedModesByDay: IModesByDay = { ...modesByDay }
        const days = commuteProfile.days || []
        days.forEach(
          (day) =>
            (updatedModesByDay[day] = [
              ...updatedModesByDay[day],
              ...commuteProfile.orderedModes,
              ...(commuteProfile.activities || [])
            ])
        )
        return updatedModesByDay
      },
      initialModesByDay
    )
  }
)

const alterCommuteDays = (originalCommute: CommuteProfileProcessed, newCommuteDays?: DaysValueEnum[]) => {
  if (originalCommute.days && originalCommute.days.length > 0 && newCommuteDays) {
    const daysDiff = difference(originalCommute.days, newCommuteDays)
    return daysDiff.length >= 0
      ? { ...originalCommute, days: daysDiff, weeklyFrequency: daysDiff.length }
      : originalCommute
  }
  return originalCommute
}

export const CreateCommuteScreenPathRegexp = new RegExp(`^(${CommuterRouting.settings.commute.create.path})$`)
export const CommuteDetailsScreenPathRegexp = new RegExp(
  `^(${CommuterRouting.settings.path}/${SettingsPages.Commute}/)[a-zA-Z0-9-]+$`
)

function CommuteProfilesProvider({ enabled, children }: CommuteProfilesProviderProps) {
  const intl = useIntl()
  const commutifiNotification = useNotifications()
  const { id: accountId } = useCurrentAccount()

  const queryId = React.useMemo(() => ['commute-profiles', accountId], [accountId])
  const { isLoading, isFetching, isError, data, refetch } = useAccountCommuteProfiles(accountId, { enabled })
  const commuteProfiles = React.useMemo(() => data?.map(extractCommuteActivities), [data])

  const [updatingDay, setUpdatingDay] = React.useState<DaysValueEnum | null>(null)
  const {
    isPending: isUpdating,
    isError: isUpdatingError,
    mutate: patch
  } = useMutation({
    mutationFn: (d: PatchRequestParams) => {
      if (d.day) {
        setUpdatingDay(d.day)
      }
      return patchCommuteProfile(d)
    },
    onSuccess: (_, variables) => {
      // Update commute profiles to reflect changes
      queryClient.setQueryData(
        queryId,
        commuteProfiles?.map((cp) => {
          if (cp.id === variables.id) {
            return {
              ...cp,
              ...variables.data,
              weeklyFrequency: variables.data.days?.length || cp.weeklyFrequency
            }
          }

          if (cp.id === variables.previousId) {
            return alterCommuteDays(cp, variables.data.days)
          }

          return cp
        })
      )

      commutifiNotification.success(
        intl.formatMessage(
          { id: 'settings.commute.routes.edit.schduleDay.success' },
          { day: getDayLocale(variables.day) }
        )
      )
    },
    onError: (_, variables) => {
      commutifiNotification.error({
        description: intl.formatMessage(
          { id: 'settings.commute.routes.edit.schduleDay.error' },
          { day: getDayLocale(variables.day) }
        )
      })
    }
  })

  const {
    isPending: isLoadingPut,
    isError: isPutError,
    mutateAsync: put
  } = useMutation({
    mutationFn: putCommuteProfile,
    onSuccess: async () => {
      if (commuteProfiles && commuteProfiles.length > 0) {
        await queryClient.invalidateQueries({ queryKey: queryId })
        await refetch()
      }

      commutifiNotification.success(intl.formatMessage({ id: 'commuteProfiles.update.success.message' }))
    },
    onError: () => {
      commutifiNotification.error({ description: intl.formatMessage({ id: 'commuteProfiles.update.error.message' }) })
    }
  })

  const {
    isPending: isCreating,
    isError: isCreatingError,
    mutateAsync: triggerPost
  } = useMutation({ mutationFn: postCommuteProfile })
  /**
   * Trigger function to create a commute profile
   * @param data - CP payload to POST
   * @param opts - Options including query params and skip notifications
   * @throws Creation error
   *
   * @returns Promise of commute profile created
   */
  const create = React.useCallback<PostFunction>(
    async (body, opts) => {
      if (body.days?.length === 1) {
        setUpdatingDay(body.days[0])
      }

      const res = await triggerPost(
        { body, queryParams: opts?.queryParams },
        {
          onError: () => {
            if (!opts?.skipNotifications) {
              commutifiNotification.error({
                description: intl.formatMessage({ id: 'commuteProfiles.create.error.message' })
              })
            }
          },
          onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: queryId })
            await refetch()
            if (!opts?.skipNotifications) {
              commutifiNotification.success(intl.formatMessage({ id: 'commuteProfiles.create.success.message' }))
            }
          }
        }
      )
      return res
    },
    [commutifiNotification, intl, queryId, refetch, triggerPost]
  )

  const {
    isPending: isDeleting,
    isError: isDeleteError,
    mutateAsync: deleteCommute
  } = useMutation({
    mutationFn: deleteCommuteProfile,
    onError: () => {
      commutifiNotification.error({ description: intl.formatMessage({ id: 'commuteProfiles.delete.error.message' }) })
    },
    onSuccess: (_, commuteProfileId) => {
      if (commuteProfiles && commuteProfiles.length > 0) {
        queryClient.setQueryData(
          queryId,
          commuteProfiles.filter((cp) => cp.id !== commuteProfileId)
        )
      }
    }
  })

  const value = React.useMemo(
    () => ({
      isLoading,
      isFetching,
      isError,
      goingModesByCommuteDays: getModesByCommuteDay(commuteProfiles),
      returnModesByCommuteDays: getModesByCommuteDay(commuteProfiles, 'return'),
      activeCommuteProfiles: makeGetInUseCommuteProfiles(true)(commuteProfiles),
      inactiveCommuteProfiles: makeGetInUseCommuteProfiles(false)(commuteProfiles),
      getCommuteDrawingSections,
      patch,
      processingDay: isUpdating || isCreating ? updatingDay : null,
      isProcessing: isUpdating || isCreating || isDeleting || isLoadingPut,
      isProcessingError: isUpdatingError || isCreatingError || isDeleteError || isPutError,
      create,
      deleteCommute,
      put,
      getCommuteProfile: (cpId: string) => getCommuteProfile(commuteProfiles)(cpId)
    }),
    [
      isLoading,
      isFetching,
      isError,
      commuteProfiles,
      patch,
      updatingDay,
      isUpdating,
      isUpdatingError,
      isCreating,
      isCreatingError,
      create,
      deleteCommute,
      isDeleting,
      isDeleteError,
      put,
      isLoadingPut,
      isPutError
    ]
  )
  if (!enabled) {
    return children
  }
  return <CommuteProfilesContext.Provider value={value}>{children}</CommuteProfilesContext.Provider>
}

const useCommuteProfiles = () => {
  const context = React.useContext(CommuteProfilesContext)
  if (!context) {
    throw new Error('useCommuteProfile must be used within a CommuteProfilesProvider')
  }
  return context
}

export { CommuteProfilesProvider, useCommuteProfiles }
