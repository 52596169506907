import React from 'react'
import enUS from '@commutifi-fe/ui/components/locales/en_US'
import { Locales } from 'constants/settings'
import { useIntl } from 'locales/index'
import {
  ConfigProvider as CommutifiUiConfigProvider,
  ConfigProviderProps as CommutifiConfigProps,
  Locale,
  NotificationConfig,
  MessageConfig,
  AntdApp
} from '@commutifi-fe/ui'
import { MomentLocalization } from '../LocaleProvider'
import { useLocalStorage } from 'react-use'
import { LocalesType, STORAGE_THEME_KEY } from '@commutifi-fe/constants'
import { useThemeDetector } from '@commutifi-fe/hooks'
import { LocalConfigProvider, AppThemes, useConfig, stripePromise } from './LocalConfigProvider'
import { ChartsThemeProvider } from '@commutifi-fe/charts'

export { AppThemes, useConfig, stripePromise }
interface ConfigProviderProps {
  locale: LocalesType
  children: React.ReactNode
  isLoadingTranslations: boolean
}

const antLocaleMapping = {
  [Locales.cs]: () => import(/* webpackChunkName: "antd_cs_CZ" */ '@commutifi-fe/ui/components/locales/cs_CZ'),
  [Locales.deDE]: () => import(/* webpackChunkName: "antd_de_DE" */ '@commutifi-fe/ui/components/locales/de_DE'),
  [Locales.enAU]: () =>
    new Promise((resolve) => {
      resolve(enUS)
    }),
  [Locales.enCA]: () =>
    new Promise((resolve) => {
      resolve(enUS)
    }),
  [Locales.enGB]: () =>
    new Promise((resolve) => {
      resolve(enUS)
    }),
  [Locales.enPH]: () =>
    new Promise((resolve) => {
      resolve(enUS)
    }),
  [Locales.enTH]: () =>
    new Promise((resolve) => {
      resolve(enUS)
    }),
  [Locales.enUS]: () =>
    new Promise((resolve) => {
      resolve(enUS)
    }),
  [Locales.enVI]: () =>
    new Promise((resolve) => {
      resolve(enUS)
    }),
  [Locales.esES]: () => import(/* webpackChunkName: "antd_es_ES" */ '@commutifi-fe/ui/components/locales/es_ES'),
  [Locales.frBE]: () => import(/* webpackChunkName: "antd_fr_BE" */ '@commutifi-fe/ui/components/locales/fr_BE'),
  [Locales.frCA]: () => import(/* webpackChunkName: "antd_fr_CA" */ '@commutifi-fe/ui/components/locales/fr_CA'),
  [Locales.itIT]: () => import(/* webpackChunkName: "antd_it_IT" */ '@commutifi-fe/ui/components/locales/it_IT'),
  [Locales.nlBE]: () => import(/* webpackChunkName: "antd_nl_BE" */ '@commutifi-fe/ui/components/locales/nl_BE'),
  [Locales.nlNL]: () => import(/* webpackChunkName: "antd_nl_NL" */ '@commutifi-fe/ui/components/locales/nl_NL'),
  [Locales.pl]: () => import(/* webpackChunkName: "antd_pl_PL" */ '@commutifi-fe/ui/components/locales/pl_PL'),
  [Locales.ptPT]: () => import(/* webpackChunkName: "antd_pt_PT" */ '@commutifi-fe/ui/components/locales/pt_PT')
} as unknown as Record<LocalesType, (() => Promise<{ default: Locale }>) | undefined>

const setMobileThemeBar = (color: string) => {
  // Mobile top bar theme
  // <!-- Chrome, Firefox OS and Opera -->
  document.querySelector("meta[name='theme-color']")?.setAttribute('content', color)
  // <!-- Windows Phone -->
  document.querySelector("meta[name='msapplication-navbutton-color']")?.setAttribute('content', color)
  // <!-- iOS Safari -->
  document.querySelector("meta[name='apple-mobile-web-app-status-bar-style']")?.setAttribute('content', color)
}

const antdAppStyle = { minHeight: '100%', display: 'flex', flex: 1 }
const notificationConfig: NotificationConfig = { bottom: 10 }
const messageConfig: MessageConfig = { duration: 6, maxCount: 3 }

/**
 * Setup Antd ConfigProvider and Commutifi UI components ConfigProvider to have a consistent design system.
 * Also setup the local (code of this project specifically) ConfigProvider component to propagate some general configuration
 * about Stripe, antd phone input, etc.
 *
 * Note: changeTheme is passed down to simplify re-rendering but we could setup a useState in the LocalConfigProvider
 *       to trigger re-renders so stripe options and more are applied correctly.
 */
export function UiComponentsConfigProvider({ children, locale, isLoadingTranslations }: ConfigProviderProps) {
  const detectedTheme = useThemeDetector()

  const [currentTheme, setCurrentTheme] = useLocalStorage<`${AppThemes}`>(STORAGE_THEME_KEY, AppThemes.LIGHT)
  const changeTheme = React.useCallback(
    (theme: `${AppThemes}`) => {
      setCurrentTheme(theme)

      const processedTheme =
        theme === AppThemes.SYSTEM ? (detectedTheme === 'dark' ? AppThemes.DARK : AppThemes.LIGHT) : theme
      switch (processedTheme) {
        case AppThemes.DARK: {
          setMobileThemeBar('#000000')
          break
        }
        default:
          setMobileThemeBar('#ffffff')
      }
    },
    [detectedTheme, setCurrentTheme]
  )

  const intl = useIntl()
  const validateMessages = React.useMemo(
    () => ({
      required: intl.formatMessage({ id: 'required' })
    }),
    [intl]
  )

  const [antdLocale, setAntdLocale] = React.useState<Locale | undefined>()
  React.useEffect(() => {
    antLocaleMapping[locale]
      ? antLocaleMapping[locale]?.()
          .then((module) => {
            setAntdLocale(module.default)
          })
          .catch(() => {
            // Nothing for now
          })
      : setAntdLocale(enUS)
  }, [locale])

  const antdConfig: CommutifiConfigProps = React.useMemo(
    () => ({
      // For ant design components translation
      theme: {
        appearance:
          currentTheme === AppThemes.DARK || (currentTheme === AppThemes.SYSTEM && detectedTheme === 'dark')
            ? AppThemes.DARK
            : AppThemes.LIGHT
      },
      locale: antdLocale,
      form: { validateMessages }
    }),
    [antdLocale, currentTheme, detectedTheme, validateMessages]
  )

  return (
    <CommutifiUiConfigProvider {...antdConfig} localeKey={locale}>
      <AntdApp style={antdAppStyle} notification={notificationConfig} message={messageConfig}>
        <LocalConfigProvider
          locale={locale}
          isLoadingTranslations={isLoadingTranslations}
          changeTheme={changeTheme}
          currentTheme={currentTheme}
        >
          <ChartsThemeProvider colorScheme={currentTheme === AppThemes.DARK ? AppThemes.DARK : AppThemes.LIGHT}>
            {children}
          </ChartsThemeProvider>
        </LocalConfigProvider>
      </AntdApp>
      <MomentLocalization locale={locale} />
    </CommutifiUiConfigProvider>
  )
}
