import { Location } from 'history'
import * as uuid from 'uuid'
import { extractUrlSearchParams } from 'utils/index'
import * as qs from 'utils/queryParams'
import storage from 'utils/storage'

export const buildURL = (url: string, params: any) => {
  if (params === null) return url

  const serializedParams = qs.stringify(params)
  if (!serializedParams) return url

  return `${url}${!url.includes('?') ? '?' : '&'}${serializedParams}`
}

export const STATE_IDENTIFIER = 'oauth:state'
export const ORIGIN_URL_IDENTIFIER = 'oauth:origin'

/**
 * Save Oauth state in the local storage using a random string:
 * \{ "RANDOM_STRING": \{ ...state \} \} by calling saveState
 * You can also pass a stateGenerator function that returns a random string
 * to protect your state. If this function is not available we fallback to a uuid
 *
 * Then when we are redirected back from the Oauth flow we can
 * extract the state from the local storage using the same random string
 * and the function extractState
 *
 * @param state - Oauth state
 */
export const stateStorage = {
  // Setting up a 30 min cleanup expiry on the OAuth state so we cleanup older states since we
  // support having multiple states at the same time. This is only for cleanup purposes
  // so we keep it simple and just check for expiration when we save an additional state and not
  // when the user extract the state on oauth callback.
  get maxAge() {
    return 30 * 60 * 1000
  },
  cleanupState(cumulatedState: Record<string, { value: any; cleanupTimestamp: number }> | undefined) {
    const previousGuardedStates = { ...(cumulatedState ?? {}) }

    Object.entries(previousGuardedStates).forEach(([stateNonce, stateObject]) => {
      if (stateObject.cleanupTimestamp && stateObject.cleanupTimestamp < new Date().getTime()) {
        delete previousGuardedStates[stateNonce]
      }
    })

    return previousGuardedStates
  },

  // Utilities to extract and access OAuth state
  async saveState<T extends Record<string, any>>(state?: T, stateGenerator?: () => Promise<string> | string) {
    const generateState = stateGenerator && typeof stateGenerator === 'function' ? stateGenerator : uuid.v4
    const nonce = await generateState()

    const previousStates = this.cleanupState(storage.get(STATE_IDENTIFIER))

    const guardedState = {
      ...previousStates,
      [nonce]: { value: state, cleanupTimestamp: new Date().getTime() + this.maxAge }
    }

    storage.put(STATE_IDENTIFIER, guardedState)

    return nonce
  },
  // state needs to match the state sent to the Oauth client
  extractState<T = any>(state: string): T {
    if (typeof state !== 'string') {
      return state
    }

    const resumeState = storage.get(STATE_IDENTIFIER)

    if (resumeState && !resumeState[state]) {
      // State random string doesn't match we should stop the process here
      throw new Error('Oauth state is not matching')
    }
    return resumeState[state]?.value ?? resumeState[state]
  }
}

interface IOAuthCallbackQueryParams<T = any> {
  state?: T
  code?: string
  accessToken?: string
  userId?: string
}

export function extractOAuthUrlParams<T>(location?: Location): IOAuthCallbackQueryParams<T> {
  const q = extractUrlSearchParams(location)
  return {
    state: q.state,
    code: q.code,
    userId: q.userId,
    accessToken: q.token
  }
}

const redirectToRoot = () => {
  window.history.replaceState({}, document.title, '/')
}

export function validateAndExtractClientState<T = any>() {
  try {
    const q = extractUrlSearchParams()
    const appState = stateStorage.extractState<T>(q.state)
    return appState
  } catch (error) {
    redirectToRoot()
    return undefined
  }
}
