import React from 'react'
import { Form, FormItemProps, Input, InputRef } from '@commutifi-fe/ui'
import { useIntl } from 'locales/index'

interface EmailInputProps extends Omit<FormItemProps, 'children'> {
  children?: React.ReactElement
}

export const EmailField = React.forwardRef<InputRef, React.ComponentProps<typeof Input>>((props, ref) => {
  const intl = useIntl()
  return <Input ref={ref} placeholder={intl.formatMessage({ id: 'placeholder.email.john' })} {...props} />
})
EmailField.displayName = 'EmailField'

/**
 * IMPORTANT: this component can only be used in and ant design Form component
 */
function EmailInput({ children, label, ...otherFormItemProps }: EmailInputProps) {
  const intl = useIntl()
  return (
    <Form.Item
      label={label ?? intl.formatMessage({ id: 'components.registerScreen.form.label.email' })}
      name="email"
      validateTrigger={['onChange', 'onBlur']}
      rules={[
        { required: true, message: intl.formatMessage({ id: 'required' }), validateTrigger: ['onChange', 'onBlur'] },
        { type: 'email', message: intl.formatMessage({ id: 'validation.email.valid' }), validateTrigger: 'onBlur' }
      ]}
      {...otherFormItemProps}
    >
      {children || <EmailField />}
    </Form.Item>
  )
}

EmailInput.Field = EmailField
export default EmailInput
