import { helpEmail } from 'constants/global'
import { FormattedMessage } from 'locales/index'
import React from 'react'
import { useHistory } from 'react-router-dom'
import EmailInput from 'shared/components/common/forms/Email'
import PasswordInput, { PasswordField } from 'shared/components/common/forms/Password'
import { ModalHeader } from 'shared/components/modals/NewPasswordModal/components/ModalHeader'
import { Button, Form, Input, Modal } from '@commutifi-fe/ui'
import { TermsAndPolicy } from './components/TermsAndPolicy'
import styles from './styles.module.scss'

export interface PasswordFormValues {
  email: string
  name: string
  password: string
}

interface NewPasswordModalProps {
  open: boolean
  email?: string | null
  name?: string | null
  onCreate: (values: PasswordFormValues) => void
  error?: any
}

const errorMessage = (
  <FormattedMessage
    id="error.refresh"
    values={{
      link: (
        <a className="u-color--accent-red-400" href={`mailto:${helpEmail}`} aria-label="Contact us">
          <FormattedMessage id="contactUs" />
        </a>
      )
    }}
  />
)

export function NewPasswordModal({ open, email, name, onCreate, error }: NewPasswordModalProps) {
  const history = useHistory()
  const [form] = Form.useForm<PasswordFormValues>()
  const handleSubmitPassword = async () => {
    try {
      const values = await form.validateFields()
      onCreate(values)
    } catch (error) {
      /* empty */
    }
  }

  React.useEffect(() => {
    email &&
      form.setFieldsValue({
        email
      })
  }, [form, email])

  React.useEffect(() => {
    name &&
      form.setFieldsValue({
        name
      })
  }, [form, name])

  const handleCancel = () => history.push('/auth')

  return (
    <Modal
      className={styles['new-password-modal-header']}
      // Prevent Modal Header Logo to overflow out of the screen
      style={{ marginTop: '52px' }}
      title={
        <ModalHeader
          title={<FormattedMessage id="signup.account.welcome" />}
          desc={<FormattedMessage id="signup.account.welcome.desc" />}
          showLogo
        />
      }
      open={open}
      onCancel={handleCancel}
      centered
      footer={
        <Button type="primary" onClick={handleSubmitPassword} className="u-margin-top--medium">
          <FormattedMessage id="signup.account.create" />
        </Button>
      }
    >
      <Form name="create_password" form={form} requiredMark={false} layout="vertical">
        <Form.Item
          name="name"
          initialValue={name}
          label={<FormattedMessage id="global.name" />}
          rules={[{ required: true, message: <FormattedMessage id="required" /> }]}
        >
          <Input placeholder="Jane Doe" disabled={Boolean(name)} />
        </Form.Item>
        <EmailInput initialValue={email}>
          <EmailInput.Field disabled={Boolean(email)} />
        </EmailInput>
        <PasswordInput>
          <PasswordField onPressEnter={handleSubmitPassword} />
        </PasswordInput>
        <TermsAndPolicy className="u-font-14" />
        {error ? <div className="u-color--accent-red-400">{errorMessage}</div> : null}
      </Form>
    </Modal>
  )
}

export default NewPasswordModal
