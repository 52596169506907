import React from 'react'
import { connect } from 'react-redux'
import { routesActions, routesSelectors } from 'store/modules/routes'

export const UserRoutesContext = React.createContext<UserRoutesProviderValue | null>(null)

export function UserRoutesProvider({ children, fetchRoutes, isFetching }: UserRoutesProviderProps) {
  return (
    <UserRoutesContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values -- Legacy
      value={{
        fetchRoutes,
        isFetching
      }}
    >
      {children}
    </UserRoutesContext.Provider>
  )
}

interface UserRoutesProviderValue {
  fetchRoutes: () => any
  isFetching?: boolean
}
interface UserRoutesProviderProps extends UserRoutesProviderValue {
  children: React.ReactNode
}

const mapStateToProps = (state: any, { disabled }: { disabled?: boolean }) => ({
  isFetching: routesSelectors.getIsFetching(state),
  disabled
})

const mapDispatchToProps = {
  fetchRoutes: routesActions.fetchRoutes
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UserRoutesProvider))

export const UserRoutesConsumer = UserRoutesContext.Consumer
