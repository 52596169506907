import React from 'react'
import { DEFAULT_LOCALE, localeToSupportedTranslationFile } from 'constants/settings'
import { defaultMessages, IntlProvider, messages } from 'locales/index'
import { LocalesType } from '@commutifi-fe/constants'
import { UiComponentsConfigProvider, useConfig, AppThemes, stripePromise } from './UiComponentsConfigProvider'

export { ConfigProvider, useConfig, AppThemes, stripePromise }

function ConfigProvider({ children, locale = DEFAULT_LOCALE }: AntdConfigProps) {
  const [isLazyLoadingTranslations, setIsLazyLoadingTranslations] = React.useState(true)
  const [lazyLoadedMessages, setLazyLoadedMessages] =
    React.useState<Partial<Awaited<ReturnType<(typeof messages)['en']>>['default']>>(defaultMessages)
  React.useEffect(() => {
    const intlTranslationsName = localeToSupportedTranslationFile(locale)
    // For now we don't use other languages than English in tests and it hard to wait for something like
    // text translations to be loaded so we just skip it when the ENV is 'test'
    if (messages[intlTranslationsName] && process.env.NODE_ENV !== 'test') {
      setIsLazyLoadingTranslations(true)
      messages[intlTranslationsName]()
        .then((module) => {
          // It's fine here it should be partial keys which works with intl
          setLazyLoadedMessages(module.default)
          setIsLazyLoadingTranslations(false)
        })
        .catch(() => {
          // Nothing for now
        })
    } else {
      setLazyLoadedMessages(defaultMessages)
      setIsLazyLoadingTranslations(false)
    }
  }, [locale])

  const messagesWithEnglishAsFallback = React.useMemo(
    () => (locale === DEFAULT_LOCALE ? defaultMessages : { ...defaultMessages, ...lazyLoadedMessages }),
    [lazyLoadedMessages, locale]
  )
  const intlData = React.useMemo(
    () => ({
      locale,
      messages: messagesWithEnglishAsFallback
    }),
    [locale, messagesWithEnglishAsFallback]
  )

  return (
    <IntlProvider {...intlData}>
      <UiComponentsConfigProvider locale={locale} isLoadingTranslations={isLazyLoadingTranslations}>
        {children}
      </UiComponentsConfigProvider>
    </IntlProvider>
  )
}

interface AntdConfigProps {
  locale?: LocalesType
  children: React.ReactNode
}
