import React from 'react'
import { Typography } from '@commutifi-fe/ui'
import { ModalLogo } from '../ModalLogo'
import styles from './styles.module.scss'

interface ModalHeaderProps {
  title: React.ReactNode
  desc: React.ReactNode
  showLogo?: boolean
}

export function ModalHeader({ title, desc, showLogo }: ModalHeaderProps) {
  return (
    <header className={styles.header}>
      {showLogo ? <ModalLogo /> : null}
      <Typography.Title level={2} className={styles.title}>
        {title}
      </Typography.Title>
      <Typography.Text size="lead">{desc}</Typography.Text>
    </header>
  )
}

export default ModalHeader
