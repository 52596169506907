import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BuildingProps } from '@commutifi/models/Buildings'
import { EnterpriseKind } from '@commutifi/models/Enterprises'
import { FeatureFlagResponse } from '@commutifi/models/FeatureFlag'
import { IOrganization } from 'shared/interfaces/Organization'
import { AdminRouting } from 'src/routing'
import { enterpriseSelectors } from 'store/modules/enterprise'
import { triggerFetchFeatureFlags, triggerFetchOrganization } from 'store/modules/enterprise/sagas'
import { initChatWidget } from 'utils/helpers'
import { CustomBranding } from '@commutifi-fe/interfaces'
import { isFeatureEnabled as isFeatureEnabledRoot } from 'utils/helpers/featureFlags'

interface ICurrentOrganizationContext {
  // Ideally we wouldn't need to use kind and orgs/enterprises would be treated the same
  // # stop discrimination!
  kind: EnterpriseKind
  organizationId: string
  currentOrganization?: IOrganization
  // URL template pattern matching multiple routes. Usually useful in
  // react-router Route component (/admins/:adminId)
  basePath: string
  // Representation of a URLs with all variables replaced (/admins/1234)
  baseRoute: string
  buildingsById: Record<string, BuildingProps>
  surveyLinkParams?: Record<string, any>
  isLoading: boolean
  fetchFeatureFlags: (orgId: string) => void
  featureFlagsError: any
  isLoadingFeatureFlags: boolean
  featureFlags: FeatureFlagResponse | null
  isFeatureEnabled: ReturnType<typeof isFeatureEnabledRoot>
  refetchOrganization: () => void
  customBranding: CustomBranding | undefined
}

interface CurrentOrganizationProviderProps {
  children: React.ReactNode
  organizationId: string
}

const CurrentOrganizationContext = React.createContext<ICurrentOrganizationContext | null>(null)

/**
 * Current Profile is the word we chose to represent information
 * about one of the possible entities on our platform:
 * commuter or enterprise or organization
 */
function CurrentOrganizationProvider({
  children,
  organizationId: urlOrganizationId
}: CurrentOrganizationProviderProps) {
  const currentOrganization: IOrganization | undefined = useSelector(enterpriseSelectors.getCurrentOrganization)
  const surveyLinkParams = useSelector(enterpriseSelectors.getSurveyLinkParams)
  const isFetching = useSelector(enterpriseSelectors.getIsFetching)
  const isFetchingFeatureFlags = useSelector(enterpriseSelectors.getIsFetchingFeatureFlags)
  const featureFlagsError = useSelector(enterpriseSelectors.getFeatureFlagsError)
  const featureFlags = useSelector(enterpriseSelectors.getManagerAvailablePages)
  const currentId = useSelector(enterpriseSelectors.getCurrentId)

  const kind = useSelector(enterpriseSelectors.getCurrentKind) || EnterpriseKind.enterprise
  const dispatch = useDispatch()
  const fetchOrg = React.useCallback((orgId: string) => dispatch(triggerFetchOrganization(orgId)), [dispatch])
  const fetchFeatureFlags = React.useCallback((orgId: string) => dispatch(triggerFetchFeatureFlags(orgId)), [dispatch])

  useEffect(() => {
    if ((urlOrganizationId && urlOrganizationId !== currentId) || !currentOrganization) {
      fetchOrg(urlOrganizationId)
    }
  }, [urlOrganizationId, fetchOrg, currentId, currentOrganization])

  useEffect(() => {
    if (currentOrganization) {
      initChatWidget({
        id: currentOrganization.id,
        organizationId: currentOrganization.id || '',
        segment: currentOrganization.segment,
        kind: currentOrganization.kind
      })
    }
  }, [currentOrganization])
  const isFeatureEnabled = React.useMemo(() => isFeatureEnabledRoot(featureFlags), [featureFlags])

  const value = React.useMemo(
    () => ({
      kind,
      organizationId: urlOrganizationId,
      basePath: AdminRouting.root.path,
      baseRoute: AdminRouting.root.getRoute(urlOrganizationId),
      currentOrganization,
      buildingsById: (currentOrganization?.buildings || []).reduce(
        (byId: Record<string, BuildingProps>, building: BuildingProps) => ({
          ...byId,
          ...(building.id && { [building.id]: building })
        }),
        {}
      ),
      surveyLinkParams,
      isLoading: (!currentOrganization && isFetching) || currentOrganization?.id !== urlOrganizationId,
      fetchFeatureFlags,
      featureFlagsError,
      isLoadingFeatureFlags: isFetchingFeatureFlags || (!featureFlags && !featureFlagsError),
      featureFlags,
      isFeatureEnabled,
      refetchOrganization: () => fetchOrg(urlOrganizationId),
      customBranding: currentOrganization?.customBranding
    }),
    [
      kind,
      urlOrganizationId,
      currentOrganization,
      surveyLinkParams,
      isFetching,
      fetchFeatureFlags,
      featureFlagsError,
      isFetchingFeatureFlags,
      featureFlags,
      isFeatureEnabled,
      fetchOrg
    ]
  )
  return <CurrentOrganizationContext.Provider value={value}>{children}</CurrentOrganizationContext.Provider>
}

const useCurrentOrganization = () => {
  const context = React.useContext(CurrentOrganizationContext)
  if (!context) {
    throw new Error('useCurrentOrganization must be used within a CurrentOrganizationProvider')
  }

  return context
}

export { CurrentOrganizationProvider, useCurrentOrganization }
