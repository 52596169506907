import { History, LocationDescriptorObject } from 'history'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as qs from 'utils/queryParams'
import { SerializerTypes, StringSerializer } from './paramsSerializer'

export enum UpdateType {
  ReplaceIn = 'replaceIn',
  PushIn = 'pushIn',
  Replace = 'replace',
  Push = 'push'
}

/**
 * remove search params that are null or an empty string.
 */
export const filterNullOrEmpty = (searchObj: Record<string, any>) =>
  Object.keys(searchObj).reduce<Record<string, any>>((filteredSearchObj, searchKey) => {
    const value = searchObj[searchKey]

    // if it isn't null or empty string, add it to the accumulated obj
    if (value !== null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
      filteredSearchObj[searchKey] = value
    }

    return filteredSearchObj
  }, {})

const createLocationSearch = (location: LocationDescriptorObject, search: Record<string, any>) => ({
  ...location,
  search: qs.stringify(filterNullOrEmpty(search))
})

const updateLocationSearch = (location: LocationDescriptorObject, newSearchValues: Record<string, any>) => {
  const prevSearch = qs.parse(location.search)
  return createLocationSearch(location, {
    ...prevSearch,
    ...newSearchValues
  })
}

/**
 * Updates the URL to match the specified search query changes.
 * If replaceIn or pushIn are used as the updateType, then parameters
 * not specified in newSearchValues are retained. If replace or push
 * are used as the updateType, then parameters not specified in newSearchValues
 * NOT are retained.
 */

export const updateUrlSearch = (
  newSearchValues: Record<string, any>,
  location: LocationDescriptorObject,
  history: History,
  updateType: UpdateType = UpdateType.ReplaceIn
) => {
  switch (updateType) {
    case UpdateType.ReplaceIn:
      history.replace(updateLocationSearch(location, newSearchValues))
      break
    case UpdateType.PushIn:
      history.push(updateLocationSearch(location, newSearchValues))
      break
    case UpdateType.Replace:
      history.replace(createLocationSearch(location, newSearchValues))
      break
    case UpdateType.Push:
      history.push(createLocationSearch(location, newSearchValues))
      break
    default:
  }
}

/**
 * Decodes a value using the serializer with the default decoder set to
 * for Strings
 */
export function decodeValue<T extends DecodeReturnType>(
  encodedValue: string,
  serializer: SerializerTypes = StringSerializer
): T {
  return serializer.decode(encodedValue) as T
}

type DecodeReturnType = ReturnType<SerializerTypes['decode']>

/**
 * Thanks to our inspiration: https://github.com/pbeshai/use-query-params
 *
 * This hook is used to handle a SINGLE query parameter in our url. Given a name, a serializer
 * config that defines how to serialize our parameter this hook will return the decoded
 * value and a setter function to update the query parameter.
 *
 * The setter takes two arguments (newValue, UpdateType) where updateType
 * is one of 'replace' | 'replaceIn' | 'push' | 'pushIn', defaulting to
 * 'replaceIn'.
 *
 * [serializer] example
 * NumberSerializer
 * where the serializer are available in paramsSerializer.js and are used to be able to
 * keep the original query param type trough the process because we need to convert
 * them to string to insert in the url
 *
 * [searchQuery] (OPTIONAL) example
 * You may optionally pass in a rawQuery searchQuery, otherwise the query is derived
 * from the location available in the react-router hook.
 *
 * @param name - Identifier of the query parameter
 * @param serializer - Serializer configuration that defined how to encode and decode the query param
 * @param searchQuery - already defined search query to use as an initial value
 */
export function useQueryParam<Serializer extends SerializerTypes = typeof StringSerializer>(
  name: string,
  // @ts-expect-error -- Legacy
  serializer: Serializer = StringSerializer,
  searchQuery?: Record<string, any>
): [
  ReturnType<Serializer['decode']> | undefined,
  (newValue: ReturnType<Serializer['decode']>, updateType?: UpdateType) => void
] {
  const history = useHistory()
  const location = useLocation()

  const parsedSearch = React.useMemo(
    () => searchQuery || qs.parse(location.search) || {},
    [location.search, searchQuery]
  )
  const [query, setQuery] = React.useState(parsedSearch)

  // Sync query with URL. Ex. if the user changes the URL manually or clicks browser go back, etc.
  React.useEffect(() => {
    if (query[name] !== parsedSearch[name]) {
      setQuery(parsedSearch)
    }
  }, [name, parsedSearch, query])

  // read in the encoded string value
  const encodedValue = query[name]
  const decodedValue = React.useMemo(
    () => {
      if (encodedValue === null) {
        return undefined
      }
      return decodeValue<ReturnType<Serializer['decode']>>(encodedValue, serializer)
    },
    // note that we use the stringified encoded value since the encoded
    // value may be an array that is recreated if a different query param
    // changes.
    [encodedValue, serializer]
  )

  // create the setter, memoizing via useCallback
  const setValue = React.useCallback(
    (newValue: any, updateType?: UpdateType) => {
      const newEncodedValue = serializer.encode(newValue)

      // Keep track of the params internally so we can have multiple instance of this hook
      // running in parallel and taking the proper previous value as a base to an update
      setQuery({ [name]: newEncodedValue })

      updateUrlSearch({ [name]: newEncodedValue }, location, history, updateType)
    },
    [history, location, name, serializer]
  )

  return [decodedValue, setValue]
}

export default useQueryParam
