import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as qs from 'utils/queryParams'
import { encodeQueryParams, SerializerTypes } from './paramsSerializer'
import { decodeValue, filterNullOrEmpty, UpdateType, updateUrlSearch } from './useQueryParam'

// Thanks to our inspiration: https://github.com/pbeshai/use-query-params

/**
 * This hook is used to handle query parameters in our url. Given a mapping object
 * that defines how to serialize each query param this hook will return the decoded
 * values in an object representing the query params and a setter function to update
 * this object.
 *
 * [paramsSerializerMap] example
 * \{ sort: StringSerializer, page: NumberSerializer \}
 * where the serializer are available in paramsSerializer.js and are used to be able to
 * keep the original query param type trough the process because we need to convert
 * them to string to insert in the url
 *
 * [decodedValues] example
 * \{ sort: '-startDate', page: 1 \}
 *
 * [setQueryParams] example
 * setQueryParams(\{ sort: '' \})
 * NOTE: here the page param will not be changed or overwrites because the default updateType
 * is set to 'replaceIn' which means we don't add a state to the react router history (not a push)
 * and we keep the other existing query params.
 * To see other updateType values please refer to paramsSerializer.js
 *
 * @param paramsSerializerMap - an object mapping query param names to serializers
 */
export function useQueryParams<T extends Record<keyof T, SerializerTypes>>(
  paramsSerializerMap: T | undefined
): [
  { [Key in keyof T]?: ReturnType<T[Key]['decode']> },
  (
    params: Partial<{
      [Key in keyof T]: ReturnType<T[Key]['decode']>
    }>,
    updateType?: UpdateType
  ) => void
] {
  if (!paramsSerializerMap) {
    throw new Error('You need to define the serializer configuration for the query params you want to use')
  }

  const history = useHistory()
  const location = useLocation()
  const parsedSearch = React.useMemo(() => qs.parse(location.search) || {}, [location.search])
  const [query, setQuery] = React.useState(parsedSearch)

  // Go through encoded query parameters and decode them using
  // util function from useQueryParam to avoid duplicating logic
  const decodedValues = React.useMemo(
    () =>
      Object.keys(paramsSerializerMap).reduce<{ [Key in keyof T]?: ReturnType<T[Key]['decode']> }>(
        (decodedQueryParams, paramName) => ({
          ...decodedQueryParams,
          [paramName]: decodeValue(query[paramName], paramsSerializerMap[paramName as keyof T])
        }),
        {}
      ),
    [query, paramsSerializerMap]
  )

  // create a setter for updating multiple query params at once
  const setQueryParams = React.useCallback(
    (
      changes: Partial<{
        [Key in keyof T]: ReturnType<T[Key]['decode']>
      }>,
      updateType?: UpdateType
    ) => {
      // encode as strings for the URL
      const encodedChanges = encodeQueryParams(changes, paramsSerializerMap)

      const updatedQuery = updateType === UpdateType.Replace ? encodedChanges : { ...query, ...encodedChanges }

      // Keep track of the params internally so we can have multiple instance of this hook
      // running in parallel and taking the proper previous value as a base to an update
      setQuery(filterNullOrEmpty(updatedQuery))

      updateType
        ? updateUrlSearch(encodedChanges, location, history, updateType)
        : updateUrlSearch(encodedChanges, location, history)
    },
    [history, location, paramsSerializerMap, query]
  )

  return [decodedValues, setQueryParams]
}

export default useQueryParams
