import React, { useState, useEffect } from 'react'
import isNil from 'lodash/isNil'

// Thanks to https://levelup.gitconnected.com/useonlinestatus-a-react-hook-to-know-when-your-app-is-offline-2d06e4e536a for the inspiration
const OnlineStatusContext = React.createContext(true)

export function OnlineStatusProvider({ children }: { children: React.ReactNode }) {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true)

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false)
    })
    window.addEventListener('online', () => {
      setOnlineStatus(true)
    })

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false)
      })
      window.removeEventListener('online', () => {
        setOnlineStatus(true)
      })
    }
  }, [])

  return <OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
}

export const useOnlineStatus = () => {
  const context = React.useContext(OnlineStatusContext)
  if (isNil(context)) {
    throw new Error('useOnlineStatus must be used within a OnlineStatusProvider')
  }

  return context
}
