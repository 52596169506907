import type { ModeName, ModeCategory, Stop } from '@commutifi/modes'
import { Overwrite } from 'utility-types'
import { CombinedModes } from '@commutifi-fe/constants'
import type { Location } from './Location'
import type { GeocoderAddressComponent } from './thirdParty/google'

export interface Score {
  id: string
  carbon: number
  cost: number
  time: number
  global: number
}

export interface Metric {
  id: string
  carbon: number
  cost: number
  duration: number
  distance: number
  carbonEmissions?: number
  emissionRate?: number
  createdAt?: string
  updatedAt?: string
}

export interface RouteGeometry {
  startAddress: string
  startLocation: Location
  endAddress: string
  endLocation: Location
}

export type LegType = 'leg' | 'stop'
export interface RouteLeg extends RouteGeometry {
  id?: string
  planId?: string | null
  externalPlanId?: string | null
  statisticalPlanId?: string | null
  removeFromGroupId?: boolean
  routeId?: string
  serviceId?: string | null
  metricsId?: string | null
  accountVehicleId?: string | null
  departureTime?: string | null
  arrivalTime?: string | null
  timezone?: string
  category?: `${ModeCategory}` | `${CombinedModes}` | null
  mode?: ModeName | null
  activity?: Stop
  transit?: TransitOption
  transferTime?: number | null
  preferredShape?: { mode: ModeName | null | undefined; transitLine?: string | null | undefined }[]
  type: string
  displayColor?: string
  textColor?: string
  name?: string | null
  // Encoded google polyline
  polyline?: string
  order?: number
  peopleCount?: number
  createdAt?: string
  updatedAt?: string
}

export interface RouteLegWithMetrics extends RouteLeg {
  metrics: Metric
}

export interface Route extends RouteGeometry {
  // More general category and mode name applied to a route
  // (ex. single mode -> same mode on the route, drive and transit and mixed modes)
  category?: string
  mode?: string
  legs: RouteLeg[]
}
export interface TransitLeg extends RouteLeg {
  displayColor?: string
  textColor?: string
  icon?: string
  distance?: number
}

export interface TransitOption
  extends Overwrite<
    Route,
    {
      legs: TransitLeg[]
    }
  > {
  durationInSec?: number
  formattedDuration?: string
}

export interface Frequency {
  days?: DaysValues[]
  weeklyFrequency: number
}
export type OptionalFrequency = Frequency | undefined

export enum DaysValueEnum {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday'
}

export type DaysValues = `${DaysValueEnum}`

export const MonToFri = [
  DaysValueEnum.Monday,
  DaysValueEnum.Tuesday,
  DaysValueEnum.Wednesday,
  DaysValueEnum.Thursday,
  DaysValueEnum.Friday
]

export type AppAvailableCategories = `${ModeCategory}` | `${CombinedModes}`

export interface CommuteTransport {
  category?: AppAvailableCategories | null
  mode?: ModeName | null
  stop?: Stop | null
}

// This format is based on the answer we get for offices suggest and home address autocomplete.
// To be able to compare the 2 locations we need to define a common format.
export interface AddressPoint {
  addressComponents?: GeocoderAddressComponent[]
  formattedAddress: string
  location: Location
}

export interface AddressPointWithTimezone extends AddressPoint {
  timezone?: string | null
}

export type AddressGeocodeValue = AddressPoint & { value?: string }

export interface Vehicle {
  id: string
  uuid: string
  year?: number
  make?: string
  model?: string
  classification?: string | null
  engineType?: string | null
  combinedMpg?: number | null
  maxMpg?: number | null
  minMpg?: number | null
  price?: number | null
  name?: string
}
