import React, { useCallback, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { getCurrentScope } from '@sentry/react'
import { FeatureFlagResponse } from '@commutifi/models/FeatureFlag'
import { BuildingProps } from '@commutifi/models/Buildings'
import { OfficeProps } from '@commutifi/models/Offices'
import { AVAILABLE_LANGUAGES, getLocaleDisplayConfig, isSupportedLocaleType } from '@commutifi-fe/constants'
import { Account, CustomBranding } from '@commutifi-fe/interfaces'
import { GenericError, PageLoader } from '@commutifi-fe/ui'

import { initChatWidget, isUuid, sendDataChatWidget, setYOffsetChatWidget, wrapActionInPromise } from 'utils/helpers'
import { accountActions, accountSelectors, triggerFetchAccount, updateAccount } from 'store/modules/accounts'
import { ExtendedAccount } from 'shared/interfaces/Account'
import { isFeatureEnabled as isFeatureEnabledRoot } from 'utils/helpers/featureFlags'
import { CountryCode, PhoneNumber, parsePhoneNumber } from 'utils/phoneNumber'
import { triggerFetchFeatureFlags } from 'store/modules/enterprise/sagas'
import { enterpriseSelectors } from 'store/modules/enterprise'
import { CookieCategory, screenSizes } from 'constants/global'
import logger, * as logRocket from 'utils/logRocket'
import { authActions } from 'store/modules/auth'
import { RootState } from 'store/index'
import { PublicRouting } from 'src/routing'
import { useConfig } from './ConfigProvider'

interface AccountContext {
  id: string
  lat: number | null | undefined
  lng: number | null | undefined
  currentAccount: ExtendedAccount
  currentLocaleConfig: (typeof AVAILABLE_LANGUAGES)[number] | undefined
  firstName: string | undefined
  lastName: string | null | undefined
  refreshAccount: (
    promise?:
      | {
          resolve: {
            (): Promise<void>
            <T>(value: T | PromiseLike<T>): Promise<T>
          }
          reject: <T = never>(reason?: any) => Promise<T>
        }
      | undefined
  ) => accountActions.FetchMeAction
  office: Partial<OfficeProps & BuildingProps>
  timezone: string
  isFetching: boolean
  error: Record<string, any>
  logout: () => Record<string, any>
  featureFlagsError: any
  featureFlags: FeatureFlagResponse | null | undefined
  isFeatureEnabled: ReturnType<typeof isFeatureEnabledRoot>
  fetchFeatureFlags: (orgId: string | null) => void
  isLoadingFeatureFlags: boolean
  dispatchUpdateAccount: typeof updateAccount
  changeLocale: (locale: string) => any
  customBranding: CustomBranding | undefined
}

const CurrentAccountContext = React.createContext<AccountContext | null>(null)

export function CurrentAccountProvider({
  children,
  urlAccountId
}: {
  children: React.ReactNode
  urlAccountId?: string | null
}) {
  const location = useLocation()
  const history = useHistory()
  const { cookieconsent, locale: storageLocale } = useConfig()
  const currentAccount = useSelector<RootState, Account | undefined>(accountSelectors.getCurrentAccount)
  const email = useSelector<RootState, string | undefined>(accountSelectors.getCurrentEmail)
  const name = useSelector<RootState, string | undefined | null>(accountSelectors.getCurrentName)
  const isFetching = useSelector(accountSelectors.getIsFetching)
  const error = useSelector(accountSelectors.getError)
  const dispatch = useDispatch()
  const fetchMe = useCallback(
    (...args: Parameters<typeof accountActions.fetchMeRequest>) => dispatch(accountActions.fetchMeRequest(...args)),
    [dispatch]
  )
  const fetchAccount = useCallback(
    (id: Parameters<typeof triggerFetchAccount>[0], queryParams: Parameters<typeof triggerFetchAccount>[1]) =>
      dispatch(triggerFetchAccount(id, queryParams)),
    [dispatch]
  )

  const dispatchLogout = React.useCallback(
    (...args: Parameters<typeof authActions.logout>) => dispatch(authActions.logout(...args)),
    [dispatch]
  )
  const logout = useCallback(async () => {
    await wrapActionInPromise(dispatchLogout, undefined)
    history.push(PublicRouting.auth.getRoute())
  }, [dispatchLogout, history])

  /**
   * FEATURE FLAGS
   */
  const isFetchingFeatureFlags = useSelector(enterpriseSelectors.getIsFetching)
  const featureFlagsError = useSelector(enterpriseSelectors.getFeatureFlagsError)
  const featureFlags = useSelector(accountSelectors.getCurrentAccountFeatureFlags)
  const fetchFeatureFlags = React.useCallback((orgId: string) => dispatch(triggerFetchFeatureFlags(orgId)), [dispatch])
  const isLoadingFeatureFlags = isFetchingFeatureFlags || (!featureFlags && !featureFlagsError)

  useEffect(() => {
    if (!currentAccount?.id) {
      fetchMe()
    }
  }, [fetchMe, currentAccount?.id])

  // Fix admins case by fetching the current user we try to access (using the id in the URL)
  // If the user is not an admin he won't be able to access that other account.
  useEffect(() => {
    if (currentAccount?.id && urlAccountId && isUuid(urlAccountId) && currentAccount.id !== urlAccountId) {
      fetchAccount(urlAccountId, { relations: ['managedEnterprises', 'office', 'office.building'] })
    }
  }, [currentAccount?.id, urlAccountId, fetchAccount])

  const isXS = useMediaQuery({ maxWidth: screenSizes.xsMaxWidth })
  useEffect(() => {
    if (currentAccount?.id && email) {
      initChatWidget({
        id: currentAccount.id,
        enterpriseId: currentAccount.enterpriseId || '',
        organizationId: currentAccount.organizationId || '',
        segment: currentAccount.segment
      })
      sendDataChatWidget(email)
      if (isXS) setYOffsetChatWidget(60)
      if (cookieconsent?.allowedCategory(CookieCategory.Analytics)) {
        // User agreed to share his data to tools like LogRocket and Sentry to improve his experience on
        // our app and have more analytics
        logRocket.identify({ email: email || '', name: name || 'Unknown', id: currentAccount.id })
        getCurrentScope().setUser({
          id: currentAccount.id,
          email
        })
      } else {
        // If user didn't agree to share personal data unless it's necessary
        // for the proper functioning of the app
        getCurrentScope().setUser({
          id: currentAccount.id,
          ip_address: 'not-your-business'
        })
        logRocket.identify({ id: currentAccount.id })
      }
    }
  }, [
    cookieconsent,
    currentAccount?.id,
    currentAccount?.enterpriseId,
    currentAccount?.organizationId,
    currentAccount?.segment,
    email,
    isXS,
    name
  ])

  const parsedPhoneNumber = React.useMemo<PhoneNumber | undefined>(() => {
    try {
      if (!currentAccount?.phoneNumber) {
        return undefined
      }
      return parsePhoneNumber(currentAccount.phoneNumber, (currentAccount.country || 'US') as CountryCode)
    } catch {
      // Since we didn't always had a standard format to save phone numbers and we can't always
      // guarantee the format will be valid we simply print a warning and avoid any crash
      logger.warn(`Couldn't parse the phone number with libphonenumber-js: ${currentAccount?.phoneNumber}`)
    }
    return undefined
  }, [currentAccount?.country, currentAccount?.phoneNumber])

  const [firstName, lastName] = React.useMemo(() => {
    const nameParts = currentAccount?.name?.split(' ')
    return [nameParts?.[0], nameParts?.splice(1).join(' ')]
  }, [currentAccount?.name])

  const extendedCurrentAccount = React.useMemo<ExtendedAccount | undefined>(
    () =>
      currentAccount
        ? {
            ...currentAccount,
            firstName,
            lastName,
            phoneNumber: parsedPhoneNumber
          }
        : undefined,
    [currentAccount, firstName, lastName, parsedPhoneNumber]
  )

  const locale = currentAccount?.preferredLocale || storageLocale
  const currentLocaleConfig = React.useMemo(() => getLocaleDisplayConfig(locale), [locale])

  const dispatchUpdateAccount = React.useCallback(
    (...args: Parameters<typeof updateAccount>) => dispatch(updateAccount(...args)),
    [dispatch]
  )
  const changeLocale = React.useCallback(
    (l: string) => {
      if (extendedCurrentAccount?.id && isSupportedLocaleType(l)) {
        dispatchUpdateAccount(extendedCurrentAccount.id, { preferredLocale: l })
      }
    },
    [dispatchUpdateAccount, extendedCurrentAccount?.id]
  )

  const isFeatureEnabled = React.useMemo(() => isFeatureEnabledRoot(featureFlags), [featureFlags])

  if (error) return <GenericError error={error} type="page" />
  // If it's fetching and it doesn't have an extendedCurrentAccount it means
  // that we don't have enough information to show the account info and it's probably
  // the first load of the dashboard
  if (!extendedCurrentAccount) return <PageLoader />
  if (urlAccountId && isUuid(urlAccountId) && urlAccountId === extendedCurrentAccount.id) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: location.pathname.replace(`/accounts/${urlAccountId}`, '')
        }}
      />
    )
  }
  return (
    <CurrentAccountContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values -- Keep for now (we can't call useMemo conditionally and we want to make sure extendedAccount is define). But if we need optimization this is a good start
      value={{
        id: extendedCurrentAccount.id,
        lat: extendedCurrentAccount.location?.lat,
        refreshAccount: fetchMe,
        lng: extendedCurrentAccount.location?.lng,
        currentAccount: extendedCurrentAccount,
        currentLocaleConfig,
        firstName: extendedCurrentAccount.firstName,
        lastName: extendedCurrentAccount.lastName,
        office: extendedCurrentAccount.office
          ? { ...extendedCurrentAccount.office, ...extendedCurrentAccount.office.building }
          : {},
        // To get the account timezone we want to get the timezone from his building
        timezone: extendedCurrentAccount.office?.building?.timezone || 'UTC',
        isFetching,
        error,
        logout,
        dispatchUpdateAccount,
        changeLocale,
        featureFlagsError,
        featureFlags,
        isFeatureEnabled,
        fetchFeatureFlags,
        isLoadingFeatureFlags,
        customBranding: extendedCurrentAccount.customBranding
      }}
    >
      {children}
    </CurrentAccountContext.Provider>
  )
}

export const useCurrentAccount = (opts?: { shouldThrow: boolean }) => {
  const context = React.useContext(CurrentAccountContext)
  if (!context) {
    if (opts?.shouldThrow === false) {
      return {} as AccountContext
    }

    throw new Error('useCurrentAccount must be used within a CurrentAccountProvider')
  }
  return context
}
