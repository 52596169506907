import {
  SupportedLocales,
  extractLocaleInformation,
  getSupportedLocaleFromAnyLocale
} from '@commutifi/constants/Locale'
import momentDurationFormatSetup from 'moment-duration-format'
import moment, { updateLocale as setMomentLocale } from 'moment-timezone'
import React from 'react'
import { getMomentLocale } from 'constants/settings'
import { useIntl } from 'locales/index'
import { LocalesType } from '@commutifi-fe/constants'

// I know it's ugly but don't want to loose my time with something not important
momentDurationFormatSetup(moment as any)

/**
 * Only reason we extract this as a component is to have access to useIntl
 * in the DOM three ancestry
 */
export function MomentLocalization({ locale }: { locale: LocalesType }) {
  const intl = useIntl()
  const momentConfig = React.useMemo<Parameters<typeof setMomentLocale>[1]>(() => {
    moment.updateLocale('en-US', { week: { dow: 1 } })
    const supportedLocale = getSupportedLocaleFromAnyLocale(locale)
    return {
      // Note that we set the start of week to monday, this might need to be
      // adjusted if we ever support countries with different week start
      week: {
        dow:
          // Hotfix US and canada having he first day set to sunday ...
          supportedLocale === SupportedLocales.enUS ||
          supportedLocale === SupportedLocales.enCA ||
          supportedLocale === SupportedLocales.frCA
            ? 1
            : (extractLocaleInformation(supportedLocale).weekInfo?.firstDay ?? 1)
      },
      durationLabelsStandard: {
        S: intl.formatMessage({ id: 'duration.labels.one.milliseconds' }),
        SS: intl.formatMessage({ id: 'duration.labels.two.milliseconds' }),
        SSS: intl.formatMessage({ id: 'duration.labels.threeOrMore.milliseconds' }),
        s: intl.formatMessage({ id: 'duration.labels.one.seconds' }),
        ss: intl.formatMessage({ id: 'duration.labels.two.seconds' }),
        sss: intl.formatMessage({ id: 'duration.labels.threeOrMore.seconds' }),
        m: intl.formatMessage({ id: 'duration.labels.one.minutes' }),
        mm: intl.formatMessage({ id: 'duration.labels.two.minutes' }),
        mmm: intl.formatMessage({ id: 'duration.labels.threeOrMore.minutes' }),
        h: intl.formatMessage({ id: 'duration.labels.one.hours' }),
        hh: intl.formatMessage({ id: 'duration.labels.two.hours' }),
        hhh: intl.formatMessage({ id: 'duration.labels.threeOrMore.hours' }),
        d: intl.formatMessage({ id: 'duration.labels.one.days' }),
        dd: intl.formatMessage({ id: 'duration.labels.two.days' }),
        ddd: intl.formatMessage({ id: 'duration.labels.threeOrMore.days' }),
        w: intl.formatMessage({ id: 'duration.labels.one.weeks' }),
        ww: intl.formatMessage({ id: 'duration.labels.two.weeks' }),
        www: intl.formatMessage({ id: 'duration.labels.threeOrMore.weeks' }),
        M: intl.formatMessage({ id: 'duration.labels.one.months' }),
        MM: intl.formatMessage({ id: 'duration.labels.two.months' }),
        MMM: intl.formatMessage({ id: 'duration.labels.threeOrMore.months' }),
        y: intl.formatMessage({ id: 'duration.labels.one.years' }),
        yy: intl.formatMessage({ id: 'duration.labels.two.years' }),
        yyy: intl.formatMessage({ id: 'duration.labels.threeOrMore.years' })
      },
      durationLabelsShort: {
        S: intl.formatMessage({ id: 'duration.labels.one.milliseconds.short' }),
        SS: intl.formatMessage({ id: 'duration.labels.two.milliseconds.short' }),
        SSS: intl.formatMessage({ id: 'duration.labels.threeOrMore.milliseconds.short' }),
        s: intl.formatMessage({ id: 'duration.labels.one.seconds.short' }),
        ss: intl.formatMessage({ id: 'duration.labels.two.seconds.short' }),
        sss: intl.formatMessage({ id: 'duration.labels.threeOrMore.seconds.short' }),
        m: intl.formatMessage({ id: 'duration.labels.one.minutes.short' }),
        mm: intl.formatMessage({ id: 'duration.labels.two.minutes.short' }),
        mmm: intl.formatMessage({ id: 'duration.labels.threeOrMore.minutes.short' }),
        h: intl.formatMessage({ id: 'duration.labels.one.hours.short' }),
        hh: intl.formatMessage({ id: 'duration.labels.two.hours.short' }),
        hhh: intl.formatMessage({ id: 'duration.labels.threeOrMore.hours.short' }),
        d: intl.formatMessage({ id: 'duration.labels.one.days.short' }),
        dd: intl.formatMessage({ id: 'duration.labels.two.days.short' }),
        ddd: intl.formatMessage({ id: 'duration.labels.threeOrMore.days.short' }),
        w: intl.formatMessage({ id: 'duration.labels.one.weeks.short' }),
        ww: intl.formatMessage({ id: 'duration.labels.two.weeks.short' }),
        www: intl.formatMessage({ id: 'duration.labels.threeOrMore.weeks.short' }),
        M: intl.formatMessage({ id: 'duration.labels.one.months.short' }),
        MM: intl.formatMessage({ id: 'duration.labels.two.months.short' }),
        MMM: intl.formatMessage({ id: 'duration.labels.threeOrMore.months.short' }),
        y: intl.formatMessage({ id: 'duration.labels.one.years.short' }),
        yy: intl.formatMessage({ id: 'duration.labels.two.years.short' }),
        yyy: intl.formatMessage({ id: 'duration.labels.threeOrMore.years.short' })
      }
      // Keeping those as an example of configuration we can change for new locales
      // durationTimeTemplates: {
      //   HMS: 'h:mm:ss',
      //   HM: 'h:mm',
      //   MS: 'm:ss'
      // },
      // durationLabelTypes: [
      //   { type: 'standard', string: '__' },
      //   { type: 'short', string: '_' }
      // ],
      // durationPluralKey: function (token: string, integerValue: number, decimalValue: number) {
      //   // Singular for a value of `1`, but not for `1.0`.
      //   if (integerValue === 1 && decimalValue === null) {
      //     return token
      //   }

      //   return token + token
      // }
    }
  }, [intl, locale])

  React.useEffect(() => {
    setMomentLocale(getMomentLocale(getSupportedLocaleFromAnyLocale(locale)), momentConfig)
  }, [momentConfig, locale])

  return null
}
